import * as React from 'react'

export default function Component() {
    React.useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://cdn.jwplayer.com/libraries/KDeLFQop.js";

        document.body.appendChild(script);
        script.onload = () => {
            window.jwplayer.key = "ABCdeFG123456SeVenABCdeFG123456SeVen==";
            window.jwplayer('myElement').setup({
                file: 'https://9264d80559c2474980be2bc3328b0fe7.mediatailor.us-east-1.amazonaws.com/v1/master/c05946d95894fc7462ef88cc98dfc97cc1f6994e/test-integration-ono/playlist.m3u8',
                mute: window.jwplayer().getEnvironment().OS.mobile,
                autostart: true,
                mute: true,
                autoPause: {
                    viewability: true
                },
                events: {
                    viewable: function (e) {
                        if (e.viewable) {
                            this.play();
                        } else {
                            this.pause();
                        }
                    }
                }
            });
        };

        return () => {
            document.body.removeChild(script);
        }
    }, []);

    return <div style={{ position: 'absolute', top: 0, left: 0, width: '100vw', height: '100vh', backgroundColor: '#000000' }}>
        <div style={{ display: 'flex', margin: '0 auto', alignItems: 'center', justifyContent: 'center', maxWidth: '75%', height: '100%', width: '100%' }}>
            <div id="myElement" />
        </div>
    </div>
}